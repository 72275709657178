<template>
  <CRow>
    <CCol col="12">
      <CModal :show.sync="op_confirm.show" :centered="true" :title="$t(op_confirm.title) + ` [ID: ${op_confirm.value.id}]`" size="lg">
        {{ $t(op_confirm.message) }}
        <template #footer>
          <CButton type="button" class="mr-2" :color="op_confirm.color_yes || 'primary'" @click="onModalConfirmed(op_confirm.response_yes || true)">{{ $t(op_confirm.yes || 'Yes') }}</CButton>
          <CButton v-if="op_confirm.no" type="button" :color="op_confirm.color_no || 'default'" @click="onModalConfirmed(op_confirm.response_no || false)">{{ $t(op_confirm.no || 'No') }}</CButton>
          <CButton v-if="op_confirm.cancel !== false" type="button" :color="op_confirm.color_cancel || 'secondary'" @click="onModalConfirmed(op_confirm.response_cancel)">{{ $t('Cancel') }}</CButton>
        </template>
      </CModal>

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit recurrent charge') + ` id: ${form.id}` : $t('Create recurrent charge') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard>
              <AMultiSelect :readonly="is_disabled" :horizontal="{ input: 'col-sm-6' }" name="imputation_id" label="Imputation" v-model="form.imputation_id" :options="lists.imputations" :isValid="isValid('imputation_id')" :errors="getErrors('imputation_id')" />
              <ACheckBox :disabled="is_disabled" label="Active" placeholder="Estado del cobro en la facturación." v-model="form.status_id" />
              <hr />
              <AInput :readonly="is_disabled" label="Name" name="name" v-model="form.name" :isValid="isValid('name')" :errors="getErrors('name')" />
              <AMultiSelect :readonly="is_disabled" :horizontal="{ input: 'col-sm-6' }" name="month_ids" label="Months" :closeOnSelect="false" :multiple="true" sercheable="true" v-model="form.month_ids" :options="available_months" :isValid="isValid('month_ids')" :errors="getErrors('month_ids')" @select="onMonthsChanged" @remove="onMonthsChanged" />
              <AMultiSelect :readonly="is_disabled" :horizontal="{ input: 'col-sm-6' }" name="apply_to" label="Apply to" v-model="form.apply_to" :options="lists.apply_options" :helper="form.apply_to ? objects.apply_options[form.apply_to].details : ''" :isValid="isValid('apply_to')" :errors="getErrors('apply_to')" @select="onApplyToChanged" @remove="onApplyToChanged" />
              <AMultiSelect :readonly="is_disabled" v-if="form.apply_to && form.apply_to !== 'ALL'" :horizontal="{ input: 'col-sm-6' }" name="building_ids" label="Buildings" :closeOnSelect="false" :multiple="true" :sercheable="true" v-model="form.building_ids" :options="lists.buildings" :isValid="isValid('building_ids')" :errors="getErrors('building_ids')" @select="onBuildingsChanged" @remove="onBuildingsChanged" />
              <AMultiSelect :readonly="is_disabled" :horizontal="{ input: 'col-sm-6' }" name="charge_method_id" label="Charge method" v-model="form.charge_method_id" :options="lists.charge_methods" :isValid="isValid('charge_method_id')" :errors="getErrors('charge_method_id')" />
              <AInput :readonly="true" v-if="form.charge_method_id === 'CFJ'" :horizontal="{ input: 'col-sm-3' }" type="number" label="Fixed fee" name="fixed_value" v-model="form.fixed_value" />
              <AMaskedInput :readonly="is_disabled" :horizontal="{ input: 'col-sm-3' }" label="Value" name="value" v-model="form.value" mask="value" maxLength="15" :isValid="isValid('value')" :errors="getErrors('value')" />
              <ATextArea :readonly="is_disabled" label="Observations" v-model="form.observations" />
            </ACard>
          </CForm>

          <ACard v-if="show_exceptions" title="Exceptions">
            <div v-for="(exception, index) of form.exceptions" :key="index">
              <transition name="fade">
                <RecurrentChargeException ref="extra_fee_exception" :exception="exception" :index="index" :lists="lists" :months="selected_months" :buildings="selected_buildings" :periods="property._periods" used="used_exceptions" :validation="[`exceptions.$each.${index}.`, isValid, getErrors]" @exceptionChanged="value => onExceptionChanged(index, value)" @removeException="removeException(index, exception)" />
              </transition>
            </div>
            <CCardFooter>
              <CButton :disabled="!can_add_exception" color="primary" class="pull-right" @click="addException">{{ '+ ' + $t('Add exception') }}</CButton>
            </CCardFooter>
          </ACard>

          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" :disabled="is_disabled" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'
import RecurrentChargeException from './elements/RecurrentChargeException'

import { DEBUG } from '@/config/config'

export default {
  name: 'RecurrentChargeForm',
  mixins: [formMixin],
  components: {
    RecurrentChargeException
  },
  computed: {
    can_add_exception() {
      if (this.duplicates) return false
      if (!this.selected_months.length || !this.selected_buildings.length) return false
      if (this.form.exceptions.filter(item => !item.month_id || !item.building_id).length) return false
      return true
    }
  },

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0, exceptions: [] },

      // Helpers
      duplicates: false,
      is_disabled: false,
      show_exceptions: false,
      available_months: [],
      selected_months: [],
      selected_buildings: [],
      undeletable_months: [],

      lists: {},
      alerts: [],
      objects: {},
      validators: {},

      op_confirm: { show: false, title: 'Remove row', message: 'Are you sure you want to delete this row?', value: {} }
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/property_recurrent_charges' + (self.form.id ? `/${self.form.id}/edit` : '/create'))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      //this.form = data.property_recurrent_charge ? data.property_recurrent_charge : { id: 0 }
      this.$set(this, 'form', { ...(data.property_recurrent_charge ? data.property_recurrent_charge : { id: 0 }), exceptions: [] })

      this.is_principal = !!this.form.is_principal
      this.lists = data._lists || {}
      this.lists.charge_methods = this.lists.charge_methods.filter(item => (this.form.id ? true : item.enabled)) // TODO: Validate only if edited value is disabled

      // TODO: function ?
      if (this.form.id) {
        this.is_disabled = this.property._periods.FAC.month_id > this.form.month_ids.sort((a, b) => b - a)[0]
      }

      this.parseView()
      this.setObjects()
      this.parsedBuildings()
      this.parseExceptions()
      this.parseMonths()

      this.onMonthsChanged()
      this.onApplyToChanged()
      this.onBuildingsChanged()

      //this.form.exceptions = []
      this.$nextTick(() => {
        this.$set(this.form, 'exceptions', data.property_recurrent_charge.exceptions)
        this.setUndeletableMonths()
      })

      this.validators = {
        main: data._validation || {},
        extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)
    },

    // Helpers
    setObjects() {
      this.objects.building_types = {}
      for (const building_type of this.lists.building_types || []) {
        this.objects.building_types[building_type.id] = building_type
      }
      this.objects.property_units = {}
      for (const property_unit of this.lists.property_units || []) {
        this.objects.property_units[property_unit.id] = property_unit
      }
      this.objects.apply_options = {}
      for (const apply_option of this.lists.apply_options || []) {
        this.objects.apply_options[apply_option.id] = apply_option
      }
    },

    onMonthsChanged() {
      this.$nextTick(() => {
        const months = []
        this.$set(this.form, 'month_ids', [...new Set([...this.undeletable_months, ...this.form.month_ids])].sort())
        for (const month of (this.form.month_ids || []).sort()) {
          months.push(this.lists.months.find(item => item.id === month))
        }
        this.selected_months = months
      })
    },

    onApplyToChanged() {
      this.$nextTick(() => {
        if (this.form.apply_to === 'ALL') {
          this.form.building_ids = []
          this.selected_buildings = this.lists.buildings
        } else this.selected_buildings = []
        this.onBuildingsChanged()
      })
    },

    onBuildingsChanged() {
      this.$nextTick(() => {
        let buildings = []
        if (this.form.apply_to) {
          if (this.form.apply_to === 'ALL') buildings = this.lists.buildings
          else if (this.form.apply_to === 'INC') {
            for (const building of (this.form.building_ids || []).sort()) {
              buildings.push(this.lists.buildings.find(item => item.id === building))
            }
          } else if (this.form.apply_to === 'EXC') {
            buildings = [...this.lists.buildings]
            for (const building of this.form.building_ids || []) {
              const idx = buildings.findIndex(item => item.id === building)
              buildings.splice(idx, 1)
            }
          }
        }
        this.selected_buildings = buildings
      })
    },

    onExceptionChanged(index, value) {
      this.$set(this.form.exceptions, index, value)
      this.runExtraValidations()
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create'), disabled: this.is_disabled }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'RecurrentCharge']: options })
    },
    parsedBuildings() {
      this.lists.buildings.forEach(item => {
        item.name = `(${this.objects.property_units[item.property_unit_id].name}) ${this.objects.building_types[item.building_type_id].name} - ${item.code}`
      })
    },
    parseExceptions() {
      //if (!this.form.exceptions.length) this.addException()
      this.show_exceptions = true
      this.validateExceptions()
    },
    parseMonths() {
      this.available_months = this.lists.months.filter(item => item.id >= this.property._periods.FAC.month_id || (this.form.month_ids || []).indexOf(item.id) >= 0)
    },
    setUndeletableMonths() {
      const undeletable_months = this.form.month_ids.filter(item => item < this.property._periods.FAC.month_id)
      if ((this.form.exceptions || []).length) {
        for (const exception of this.form.exceptions) {
          //if (exception.month_id < this.property._periods.FAC.month_id && undeletable_months.indexOf(exception.month_id) === -1) undeletable_months.push(exception.month_id)
          if (/*exception.id &&*/ undeletable_months.indexOf(exception.month_id) === -1) undeletable_months.push(exception.month_id)
        }
      }
      this.undeletable_months = undeletable_months.sort()
    },

    // Exceptions

    addException() {
      if (this.form.exceptions.length < 10) {
        // TODO: remove this
        // TODO: validate available exceptions
        if (this.can_add_exception) this.form.exceptions.push({ id: 0 })
      }
      //if (this.form.exceptions.length === 1) this.validateExceptions()
      this.runExtraValidations()
    },
    removeException(index, exception) {
      this.op_confirm.value = { index, id: exception.id }
      this.op_confirm.show = true
    },
    onModalConfirmed(response) {
      this.op_confirm.show = false
      if (response) {
        if (this.op_confirm.value.id) this.form.remove_exceptions = [...(this.form.remove_exceptions || []), ...[this.op_confirm.value.id]]
        this.form.exceptions.splice(this.op_confirm.value.index, 1)
        //if (this.form.exceptions.length === 0) this.addException() // this.removeValidator('exceptions')
        this.runExtraValidations()
        //this.setUndeletableMonths()
      }
    },
    validateExceptions() {
      this.$nextTick(() => {
        if (this.validators?.extra?.exceptions) {
          this.injectValidator('exceptions', 0)
          this.runExtraValidations()
        }
      })
    },

    runExtraValidations() {
      if (this.form.apply_to === 'ALL') this.form.building_ids = null

      // Duplicated exceptions
      this.duplicates = false
      if ((this.form.exceptions || []).length) {
        this.form.exceptions.forEach(item => (item.is_duplicated = false))
        for (let i = 0; i < this.form.exceptions.length - 1; i++) {
          const obj_i = this.form.exceptions[i]
          for (let x = i + 1; x < this.form.exceptions.length; x++) {
            const obj_x = this.form.exceptions[x]
            if (obj_i.month_id && obj_i.building_id && obj_i.month_id === obj_x.month_id && obj_i.building_id === obj_x.building_id) {
              this.duplicates = true
              this.form.exceptions[x].is_duplicated = true
            }
          }
        }
      }
    },

    // SUBMIT

    submit() {
      this.runExtraValidations()
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      if (this.duplicates) return

      const self = this
      self.form = self.stripNumbers(self.form, ['value'])
      self.$http[self.form.id ? 'put' : 'post']('admin/property_recurrent_charges', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/processes/recurrent_charges/${response.data.property_recurrent_charge.id}/edit`).catch(() => {})
          self.showAlert(`Recurrent charge ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the recurrent charge.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
